@font-face {
  font-family: "BrownPro";
  src: url("/fonts/BrownPro-Thin.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "BrownPro";
  src: url("/fonts/BrownPro-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BrownPro";
  src: url("/fonts/BrownPro-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: black;
  margin: 0;
  font-family: "BrownPro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headroom-wrapper {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  position: fixed;
  height: 80px !important;
}

.headroom {
  color: #fff;
  width: 100%;
  transition: transform 150ms ease-in;
}

.headroom a[data-logo="dark"] {
  display: none;
}

.headroom-header {
  background: rgba(34, 34, 34, 0.7);
}

.headroom--unfixed {
  position: fixed;
  transform: translateY(0);
}

.headroom--scrolled {
  transition: transform 150ms ease-in;
}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}

.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}

.headroom-header[data-variant="transparent"] {
  background: none;
  color: #222222;
}

.headroom-header[data-variant="transparent"] a[data-logo="dark"] {
  display: inline-flex;
}

.headroom-header[data-variant="transparent"] a[data-logo="default"] {
  display: none;
}

.headroom--unpinned .headroom-header[data-variant="transparent"],
.headroom--scrolled .headroom-header[data-variant="transparent"] {
  background: rgba(34, 34, 34, 0.7);
  color: #fff;
}

.headroom--unpinned
  .headroom-header[data-variant="transparent"]
  a[data-logo="default"],
.headroom--scrolled
  .headroom-header[data-variant="transparent"]
  a[data-logo="default"] {
  display: inline-flex;
}

.headroom--unpinned
  .headroom-header[data-variant="transparent"]
  a[data-logo="dark"],
.headroom--scrolled
  .headroom-header[data-variant="transparent"]
  a[data-logo="dark"] {
  display: none;
}

@media only screen and (min-width: 62em) {
  .headroom-wrapper {
    height: 104px !important;
  }

  .headroom--unpinned {
    position: fixed;
    transform: translateY(0);
  }
}

option {
  background-color: #2f3132 !important;
  color: white !important;
}

.keen-slider {
  display: flex;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.keen-slider__slide {
  position: relative;
  overflow: visible;
  width: 100%;
  min-height: 100%;
}
.keen-slider[data-keen-slider-v] {
  flex-wrap: wrap;
}
.keen-slider[data-keen-slider-v] .keen-slider__slide {
  width: 100%;
}
.keen-slider[data-keen-slider-moves] * {
  pointer-events: none;
}

.slider-wrap {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 760px;
  overflow-x: hidden;
  overflow-y: visible;
}

.slider {
  /*transition: 533ms ease-in-out;*/
  height: 300px;
}

.item {
  display: inline-flex;
  background-color: white;
  height: 192px;
  transition: 533ms ease-in-out;
}

.item--active {
  position: relative;
  top: 40px;
  height: 226px;
  background-color: coral;
  /*position: absolute;*/
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

.placeholder-loading::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 1.5s linear infinite;
}
